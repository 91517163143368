<template>
  <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="primary" :header="orders" text="الطلبات">
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="info" :header="clients" text="العملاء">
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="warning" :header="categories" text="الفئات">
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        color="danger"
        :header="sub_categories"
        text="الفئات الفرعية"
      >
      </CWidgetDropdown>
    </CCol>
  </CRow>
</template>

<script>
import $ from "jquery";
import swal from 'sweetalert';
export default {
  name: "WidgetsDropdown",
  data() {
    return {
      clients: "",
      categories: "",
      sub_categories: "",
      orders: "",
    };
  },
  created() {
    console.log($('.loading').show())
    this.$http.get(`${process.env.VUE_APP_URL}statistics`).then((response) => {
      this.data = response.data;
      this.clients = this.data.clients.toString();
      this.categories = this.data.categories.toString();
      this.sub_categories = this.data.sub_categories.toString();
      this.orders = this.data.orders.toString();
      console.log($('.loading').hide())
    })
    .catch(function (error) {
      this.output = error;
    });
  }
};
</script>